import React, { Component } from 'react';
import actions from '../../../js/actions';
import { connect } from 'react-redux';
import Table from '../../../components/Table';
import { Link } from 'react-router-dom';
import actionsLawsuit from '../../lawsuit/store/actions';
import { isHasPermission } from '../../../utils/helpres';
import { Filter } from '../../lawsuit/components/filter';

const mapStateToProps = (state) => ({
  account: state.AppReducer.account,
  lawsuits: state.AppReducer.lawsuits,
  paramsLawsuits: state.AppReducer.paramsLawsuits,
  isLoadingLawsuits: state.AppReducer.isLoadingLawsuits,
  destroyLawsuit: state.LawsuitReducer.destroyLawsuit,
  paramsCustomer: state.AppReducer.paramsCustomer,
  customers: state.AppReducer.customers,
  isLoadingCustomers: state.AppReducer.isLoadingCustomers,
});

const mapDispatchToProps = (dispatch) => ({
  getListCustomer: (paramsCustomer) => dispatch(actions.getListCustomer(paramsCustomer)),
  getListLawsuits: (paramsLawsuits) =>
    dispatch(actions.getListLawsuits(paramsLawsuits, 1)),
  deleteLawsuit: async (paramsLawsuits, id) => {
    await dispatch(actionsLawsuit.deleteLawsuit(id, 'delete'));
    dispatch(actions.getListLawsuits(paramsLawsuits, 1));
  },
  changeSearchedStringLawsuits: async (paramsLawsuits) => {
    await dispatch(actions.changeSearchedStringLawsuits(paramsLawsuits));
    dispatch(actions.getListLawsuits(paramsLawsuits, 1));
  },
});

class LawsuitArchive extends Component {
  constructor(props) {
    super(props);
    props.getListCustomer(props.paramsCustomer);
    props.getListLawsuits(props.paramsLawsuits);
    props.changeActiveItemSideBar(6, 'أرشيف القضايا');
    document.title = 'أرشيف القضايا';
  }
  render() {
    const {
      customers,
      lawsuits,
      account,
      destroyLawsuit,
      isLoadingLawsuits,
      paramsLawsuits,
    } = this.props;
    let isPermissionEdit = isHasPermission(
      account.login_access.permissions,
      'تعديل-القضايا',
    );
    let isPermissionDelete = isHasPermission(
      account.login_access.permissions,
      'حذف-القضايا',
    );
    let isPermissionAdd = isHasPermission(
      account.login_access.permissions,
      'إضافة-القضايا',
    );
    let data = [];
    if (customers && customers.data && customers.data.length > 0) {
      data = customers.data;
    }
    return (
      <div className="jalaset_main">
        <div className="jalaset_middle">
          <div className="table_controls">
            <div className="sort_grp">
              <div className="sort_grp__txt">ترتيب حسب</div>
              <Filter
                data={data}
                selectedItem={
                  this.props.location.state && this.props.location.state.customer_id
                }
                changeSearchedStringLawsuits={this.props.changeSearchedStringLawsuits}
                paramsSessions={paramsLawsuits}
              />
            </div>
          </div>
          <Table
            class1="lawsuit__judgements_table"
            class2="lawsuit_table"
            withTitle={false}
            colSpan={8}
            dataTable="lawsuits"
            messageDelete="أنت بصدد حذف القضية"
            action={true}
            isLoading={isLoadingLawsuits}
            params={paramsLawsuits}
            changeSearchedString={this.props.changeSearchedStringLawsuits}
            show={false}
            isPermissionEdit={isPermissionEdit}
            isPermissionDelete={isPermissionDelete}
            isPermissionAdd={isPermissionAdd}
            account={account}
            data={lawsuits}
            //sessions={sessions}
            title="الجلسات"
            deletePath={this.props.deleteLawsuit}
            destroy={destroyLawsuit}
            destroyYes={true}
            headerItems={[
              'عدد القضية',
              'نوع القضية',
              'المحكمة',
              'المدعي',
              'الملف',
              'الضد',
              'تاريخ الجلسة',
            ]}
          />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LawsuitArchive);
