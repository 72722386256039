import React, { Component } from 'react';
import { doc } from 'prettier';
import { Link } from 'react-router-dom';

class Notification extends Component {
  constructor(props) {
    super(props);
    props.changeActiveItemSideBar(6, 'الأرشيف');
    document.title = 'الأرشيف';
  }
  render() {
    return (
      <div className="archives_main">
        <div className="archives_middle">
          <div className="all_folders" style={{ flexWrap: 'inherit' }}>
            <Link to="/archives/customers">
              <div className="single_folder">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="143.397"
                  height="109.924"
                  viewBox="0 0 143.397 109.924"
                >
                  <g id="folder">
                    <g id="Group_120" data-name="Group 120" transform="translate(0 0)">
                      <path
                        id="Path_163"
                        data-name="Path 163"
                        d="M130.744,62.043H72.514l-11.6-13.791a1.758,1.758,0,0,0-1.406-.633H12.653A12.779,12.779,0,0,0,0,60.413v84.351a12.779,12.779,0,0,0,12.653,12.779H130.744A12.779,12.779,0,0,0,143.4,144.764V74.823A12.779,12.779,0,0,0,130.744,62.043Z"
                        transform="translate(0 -47.619)"
                        fill="#ca2946"
                      />
                    </g>
                  </g>
                  <path
                    id="folder-2"
                    data-name="folder"
                    d="M42.006,14.99H39V10.241A5.647,5.647,0,0,0,33.356,4.6H25.443a3.761,3.761,0,0,1-2.471-.924L20.45,1.481A5.362,5.362,0,0,0,16.931.164H5.64A5.647,5.647,0,0,0,0,5.8V35.543a6.5,6.5,0,0,0,6.15,6.482v.009H38.107a5.372,5.372,0,0,0,5.255-4.306l3.9-16.309.007-.031a5.36,5.36,0,0,0-5.259-6.4ZM1.6,35.543V5.8A4.048,4.048,0,0,1,5.641,1.761H16.931a3.763,3.763,0,0,1,2.471.925L21.924,4.88A5.36,5.36,0,0,0,25.443,6.2h7.913A4.049,4.049,0,0,1,37.4,10.241V14.99h-.759a4.378,4.378,0,0,0-4.3-3.573h-.264a4.455,4.455,0,0,0-4.375-3.649H9.345a5.366,5.366,0,0,0-5.36,5.36V39.744a4.9,4.9,0,0,1-2.387-4.2ZM32.345,13.014A2.779,2.779,0,0,1,35,14.99H21.442A5.34,5.34,0,0,0,16.231,19.1L11.263,36.638l-.009.033a4.889,4.889,0,0,1-1.111,2.136V16.777a3.768,3.768,0,0,1,3.764-3.764ZM5.581,40.351V13.128A3.768,3.768,0,0,1,9.345,9.364H27.706a2.855,2.855,0,0,1,2.737,2.053H13.906a5.366,5.366,0,0,0-5.36,5.36V39.988a4.905,4.905,0,0,1-2.965.363ZM45.7,21.064l-3.9,16.307L41.8,37.4a3.773,3.773,0,0,1-3.692,3.034H10.765a6.479,6.479,0,0,0,2.039-3.38l4.968-17.541.009-.033a3.75,3.75,0,0,1,3.662-2.9H42.006a3.764,3.764,0,0,1,3.7,4.478Zm0,0"
                    transform="translate(48.906 39.936)"
                    fill="#fff"
                    stroke="#fff"
                    strokeWidth="1"
                  />
                </svg>
                ملفات الحرفاء
              </div>
            </Link>
            <Link to="/archives/bill">
              <div className="single_folder">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="143.397"
                  height="109.924"
                  viewBox="0 0 143.397 109.924"
                >
                  <g id="folder">
                    <g id="Group_120" data-name="Group 120" transform="translate(0 0)">
                      <path
                        id="Path_163"
                        data-name="Path 163"
                        d="M130.744,62.043H72.514l-11.6-13.791a1.758,1.758,0,0,0-1.406-.633H12.653A12.779,12.779,0,0,0,0,60.413v84.351a12.779,12.779,0,0,0,12.653,12.779H130.744A12.779,12.779,0,0,0,143.4,144.764V74.823A12.779,12.779,0,0,0,130.744,62.043Z"
                        transform="translate(0 -47.619)"
                        fill="#ca2946"
                      />
                    </g>
                  </g>
                  <g id="browser" transform="translate(44.804 10.1)">
                    <path
                      id="Path_107"
                      data-name="Path 107"
                      d="M42.465,30H7.508A7.517,7.517,0,0,0,0,37.571V67.327A7.771,7.771,0,0,0,7.787,75.1H42.213A7.771,7.771,0,0,0,50,67.327V37.57A7.508,7.508,0,0,0,42.465,30Zm-.252,2.451a5.346,5.346,0,0,1,5.336,5.342V39.8H2.451v-2.01a5.345,5.345,0,0,1,5.336-5.342Zm0,40.2H7.787a5.326,5.326,0,0,1-5.336-5.32V42.337h45.1v24.99A5.326,5.326,0,0,1,42.213,72.647Z"
                      fill="#fff"
                    />
                    <path
                      id="Path_108"
                      data-name="Path 108"
                      d="M94.536,103.238a1.4,1.4,0,1,0-1.4-1.4A1.4,1.4,0,0,0,94.536,103.238Z"
                      transform="translate(-85.525 -64.677)"
                      fill="#fff"
                    />
                    <path
                      id="Path_109"
                      data-name="Path 109"
                      d="M151.734,103.238a1.4,1.4,0,1,0-1.4-1.4A1.4,1.4,0,0,0,151.734,103.238Z"
                      transform="translate(-138.049 -64.677)"
                      fill="#fff"
                    />
                    <path
                      id="Path_110"
                      data-name="Path 110"
                      d="M208.94,103.238a1.4,1.4,0,1,0-1.4-1.4A1.4,1.4,0,0,0,208.94,103.238Z"
                      transform="translate(-190.582 -64.677)"
                      fill="#fff"
                    />
                    <path
                      id="Path_111"
                      data-name="Path 111"
                      d="M249.45,454.475a8.3,8.3,0,0,1-3.46-.787,1.219,1.219,0,0,0-1.634.568,1.166,1.166,0,0,0,.6,1.574,10.474,10.474,0,0,0,4.479.878h.007a1.121,1.121,0,1,0,.008-2.233Z"
                      transform="translate(-224.288 -388.963)"
                      fill="#fff"
                    />
                    <path
                      id="Path_112"
                      data-name="Path 112"
                      d="M183.112,381.13a1.23,1.23,0,1,0-2.36.692,10.768,10.768,0,0,0,2.2,4.032,1.23,1.23,0,1,0,1.861-1.607A8.331,8.331,0,0,1,183.112,381.13Z"
                      transform="translate(-165.939 -321.63)"
                      fill="#fff"
                    />
                    <path
                      id="Path_113"
                      data-name="Path 113"
                      d="M199.029,231.8l-1.842.587a10.837,10.837,0,0,0-21.172,3.269,1.23,1.23,0,1,0,2.459,0,8.378,8.378,0,0,1,16.369-2.521l-1.969.628a.832.832,0,0,0-.4,1.3,1.04,1.04,0,0,0,.324.269l4.019,2.083a.822.822,0,0,0,1.109-.352l2.077-4.02a1.077,1.077,0,0,0,.106-.415A.831.831,0,0,0,199.029,231.8Z"
                      transform="translate(-161.635 -178.891)"
                      fill="#fff"
                    />
                  </g>
                </svg>
                المحاسبة
              </div>
            </Link>
            <Link to="/archives/lawsuits">
              <div className="single_folder">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="143.397"
                  height="109.924"
                  viewBox="0 0 143.397 109.924"
                >
                  <g id="folder">
                    <g id="Group_120" data-name="Group 120" transform="translate(0 0)">
                      <path
                        id="Path_163"
                        data-name="Path 163"
                        d="M130.744,62.043H72.514l-11.6-13.791a1.758,1.758,0,0,0-1.406-.633H12.653A12.779,12.779,0,0,0,0,60.413v84.351a12.779,12.779,0,0,0,12.653,12.779H130.744A12.779,12.779,0,0,0,143.4,144.764V74.823A12.779,12.779,0,0,0,130.744,62.043Z"
                        transform="translate(0 -47.619)"
                        fill="#ca2946"
                      />
                    </g>
                  </g>
                  <g id="judgement" transform="translate(45.127 38.763)">
                    <path
                      id="Path_79"
                      data-name="Path 79"
                      d="M46.252,7.27a.666.666,0,0,0-.666.666v6.152A3.192,3.192,0,0,1,42.4,17.276H31.2a.666.666,0,0,0-.573.327l-1.051,1.775L28.524,17.6a.666.666,0,0,0-.573-.327,8.472,8.472,0,0,1-2.371-.13v-.825A6.948,6.948,0,0,0,23.3,6.443V4.52a3.192,3.192,0,0,1,3.189-3.188H42.4A3.192,3.192,0,0,1,45.585,4.52a.666.666,0,0,0,1.332,0A4.526,4.526,0,0,0,42.4,0H26.486a4.526,4.526,0,0,0-4.521,4.52V5.845a6.976,6.976,0,0,0-8.009,10.476v1.343a5.681,5.681,0,0,0,.841,2.981,6.949,6.949,0,0,0-4.024,6.324v3.274H2.4a.666.666,0,0,0,0,1.332H4.252l1.134,6.242a2.027,2.027,0,0,0,2,1.665h1.3c0,1.753,0,3.549-.033,5.316a.666.666,0,0,0,1.332.022c.03-1.778.033-3.58.033-5.338h1.642a.666.666,0,0,0,0-1.332q-2.136,0-4.272,0A.7.7,0,0,1,6.7,37.58l-1.091-6H33.929l-1.091,6a.7.7,0,0,1-.685.571H15.2a.666.666,0,0,0,0,1.332H29.556c0,1.8,0,3.551,0,5.328a.666.666,0,0,0,1.332,0c0-1.777,0-3.526,0-5.326h1.265a2.027,2.027,0,0,0,2-1.666l1.134-6.242h1.854a.666.666,0,0,0,0-1.332H33.885V28.307a.666.666,0,0,0-.666-.666H32.043V26.194h5.094a.666.666,0,0,0,0-1.332H32.043V22.749a.666.666,0,0,0-.666-.666H27.949a.666.666,0,0,0-.662.589,6.957,6.957,0,0,0-2.549-2.026,5.681,5.681,0,0,0,.78-2.142,9.629,9.629,0,0,0,2.053.1L29,21.025a.672.672,0,0,0,1.147,0l1.43-2.417H42.4a4.526,4.526,0,0,0,4.521-4.52V7.936a.666.666,0,0,0-.666-.666ZM19.768,6.828a5.653,5.653,0,0,1,5.509,6.961,2.309,2.309,0,0,0-2-1.157H16.262a2.31,2.31,0,0,0-2,1.159,5.653,5.653,0,0,1,5.509-6.963Zm0,19.15-1.625-2.789a5.932,5.932,0,0,0,3.251,0Zm.013-3.9a4.448,4.448,0,0,1-4.493-4.418V14.919a.967.967,0,0,1,.973-.955h7.012a.966.966,0,0,1,.973.957v2.743a4.447,4.447,0,0,1-4.466,4.418ZM12.106,26.97a5.623,5.623,0,0,1,3.548-5.243s2.39,3.939,3.434,5.731v2.785H12.106Zm20.446,3.274H26.773v-1.27h5.779Zm-3.937-6.829h2.1v4.226h-2.1Zm-1.333,2.268v1.958H26.107a.666.666,0,0,0-.666.666v1.936h-5.02V27.5c1.052-1.805,3.46-5.776,3.46-5.776a5.623,5.623,0,0,1,3.4,3.956Z"
                      fill="#fff"
                      stroke="#fff"
                      strokeWidth="1"
                    />
                    <path
                      id="Path_80"
                      data-name="Path 80"
                      d="M300.895,36.819a.666.666,0,0,0-.666-.666H294.12v-.863a.666.666,0,0,0-1.332,0v.863h-6.108a.666.666,0,1,0,0,1.332h1.477l-1.095,2.869a2.207,2.207,0,1,0,4.124,0l-1.095-2.869h2.7v7.764h-2.949a.666.666,0,1,0,0,1.332h7.231a.666.666,0,1,0,0-1.332H294.12V37.485h2.7l-1.095,2.869a2.207,2.207,0,1,0,4.124,0l-1.095-2.869h1.476a.666.666,0,0,0,.666-.666Zm-11.052,4.818a.876.876,0,0,1-1.537-.808l.817-2.141.817,2.141A.863.863,0,0,1,289.843,41.636Zm8.662,0a.876.876,0,0,1-1.537-.808l.817-2.141.817,2.141A.863.863,0,0,1,298.505,41.636Z"
                      transform="translate(-259.012 -31.548)"
                      fill="#fff"
                      stroke="#fff"
                      strokeWidth="1"
                    />
                  </g>
                </svg>
                القضايا
              </div>
            </Link>
            <Link to="/archives/contracts">
              <div className="single_folder">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="143.397"
                  height="109.924"
                  viewBox="0 0 143.397 109.924"
                >
                  <g id="folder">
                    <g id="Group_120" data-name="Group 120" transform="translate(0 0)">
                      <path
                        id="Path_163"
                        data-name="Path 163"
                        d="M130.744,62.043H72.514l-11.6-13.791a1.758,1.758,0,0,0-1.406-.633H12.653A12.779,12.779,0,0,0,0,60.413v84.351a12.779,12.779,0,0,0,12.653,12.779H130.744A12.779,12.779,0,0,0,143.4,144.764V74.823A12.779,12.779,0,0,0,130.744,62.043Z"
                        transform="translate(0 -47.619)"
                        fill="#ca2946"
                      />
                    </g>
                  </g>
                  <g id="paper" transform="translate(47.139 36.5)">
                    <path
                      id="Path_122"
                      data-name="Path 122"
                      d="M20.849,28.068H2.686A.686.686,0,0,1,2,27.382V2.686A.686.686,0,0,1,2.686,2H15.038a.686.686,0,0,1,.485.2l5.81,5.808a.686.686,0,0,1,.2.485V27.382A.686.686,0,0,1,20.849,28.068ZM3.372,26.7H20.163V8.778L14.754,3.372H3.372Z"
                      transform="translate(-2 -2)"
                      fill="#fff"
                      stroke="#fff"
                      strokeWidth="1"
                    />
                    <path
                      id="Path_123"
                      data-name="Path 123"
                      d="M26.5,9.187h-5.81a.686.686,0,0,1-.686-.686V2.686a.686.686,0,1,1,1.372,0V7.815H26.5a.686.686,0,0,1,0,1.372Z"
                      transform="translate(-7.654 -2)"
                      fill="#fff"
                      stroke="#fff"
                      strokeWidth="1"
                    />
                    <path
                      id="Path_124"
                      data-name="Path 124"
                      d="M19.271,31.793H6.926a.686.686,0,0,1,0-1.372H19.271a.686.686,0,1,1,0,1.372Z"
                      transform="translate(-3.331 -10.924)"
                      fill="#fff"
                      stroke="#fff"
                      strokeWidth="1"
                    />
                    <path
                      id="Path_125"
                      data-name="Path 125"
                      d="M19.271,24.846H6.926a.686.686,0,0,1,0-1.372H19.271a.686.686,0,1,1,0,1.372Z"
                      transform="translate(-3.331 -8.743)"
                      fill="#fff"
                      stroke="#fff"
                      strokeWidth="1"
                    />
                    <path
                      id="Path_126"
                      data-name="Path 126"
                      d="M19.271,17.9H6.926a.686.686,0,0,1,0-1.372H19.271a.686.686,0,1,1,0,1.372Z"
                      transform="translate(-3.331 -6.561)"
                      fill="#fff"
                      stroke="#fff"
                      strokeWidth="1"
                    />
                    <path
                      id="Path_127"
                      data-name="Path 127"
                      d="M13.464,10.951H6.926a.686.686,0,0,1,0-1.372h6.538a.686.686,0,1,1,0,1.372Z"
                      transform="translate(-3.331 -4.38)"
                      fill="#fff"
                      stroke="#fff"
                      strokeWidth="1"
                    />
                    <path
                      id="Path_128"
                      data-name="Path 128"
                      d="M62.373,60.068H44.21a.686.686,0,0,1-.686-.686v-24.7A.686.686,0,0,1,44.21,34H56.562a.686.686,0,0,1,.485.2l5.811,5.808a.686.686,0,0,1,.2.485V59.382A.686.686,0,0,1,62.373,60.068ZM44.9,58.7H61.687V40.778l-5.409-5.406H44.9Z"
                      transform="translate(-15.038 -12.048)"
                      fill="#fff"
                      stroke="#fff"
                      strokeWidth="1"
                    />
                    <path
                      id="Path_129"
                      data-name="Path 129"
                      d="M68.026,41.187H62.215a.686.686,0,0,1-.686-.686V34.686a.686.686,0,0,1,1.372,0v5.129h5.125a.686.686,0,1,1,0,1.372Z"
                      transform="translate(-20.692 -12.048)"
                      fill="#fff"
                      stroke="#fff"
                      strokeWidth="1"
                    />
                    <path
                      id="Path_130"
                      data-name="Path 130"
                      d="M60.794,63.793H48.446a.686.686,0,1,1,0-1.372H60.794a.686.686,0,1,1,0,1.372Z"
                      transform="translate(-16.368 -20.972)"
                      fill="#fff"
                      stroke="#fff"
                      strokeWidth="1"
                    />
                    <path
                      id="Path_131"
                      data-name="Path 131"
                      d="M60.794,56.846H48.446a.686.686,0,1,1,0-1.372H60.794a.686.686,0,1,1,0,1.372Z"
                      transform="translate(-16.368 -18.791)"
                      fill="#fff"
                      stroke="#fff"
                      strokeWidth="1"
                    />
                    <path
                      id="Path_132"
                      data-name="Path 132"
                      d="M60.794,49.9H48.446a.686.686,0,1,1,0-1.372H60.794a.686.686,0,1,1,0,1.372Z"
                      transform="translate(-16.368 -16.609)"
                      fill="#fff"
                      stroke="#fff"
                      strokeWidth="1"
                    />
                    <path
                      id="Path_133"
                      data-name="Path 133"
                      d="M54.994,42.951H48.456a.686.686,0,0,1,0-1.372h6.538a.686.686,0,0,1,0,1.372Z"
                      transform="translate(-16.372 -14.428)"
                      fill="#fff"
                      stroke="#fff"
                      strokeWidth="1"
                    />
                    <path
                      id="Path_134"
                      data-name="Path 134"
                      d="M55.226,21.207a.686.686,0,0,1-.686-.686v-9h-9.6a.686.686,0,1,1,0-1.372h10.29a.686.686,0,0,1,.686.686v9.685A.686.686,0,0,1,55.226,21.207Z"
                      transform="translate(-15.266 -4.559)"
                      fill="#fff"
                      stroke="#fff"
                      strokeWidth="1"
                    />
                    <path
                      id="Path_135"
                      data-name="Path 135"
                      d="M47.181,12.739a.686.686,0,0,1-.485-.2l-2.245-2.245a.686.686,0,0,1,0-.97L46.7,7.079a.686.686,0,1,1,.97.97l-1.76,1.76,1.76,1.76a.686.686,0,0,1-.485,1.171Z"
                      transform="translate(-15.266 -3.532)"
                      fill="#fff"
                      stroke="#fff"
                      strokeWidth="1"
                    />
                    <path
                      id="Path_136"
                      data-name="Path 136"
                      d="M23.726,58.789H13.436a.686.686,0,0,1-.686-.686V48.418a.686.686,0,1,1,1.372,0v9h9.6a.686.686,0,0,1,0,1.372Z"
                      transform="translate(-5.375 -16.36)"
                      fill="#fff"
                      stroke="#fff"
                      strokeWidth="1"
                    />
                    <path
                      id="Path_137"
                      data-name="Path 137"
                      d="M25.165,64.439a.686.686,0,0,1-.486-1.171l1.76-1.76-1.76-1.76a.686.686,0,1,1,.971-.97l2.244,2.245a.686.686,0,0,1,0,.97L25.65,64.238A.686.686,0,0,1,25.165,64.439Z"
                      transform="translate(-9.058 -19.765)"
                      fill="#fff"
                      stroke="#fff"
                      strokeWidth="1"
                    />
                  </g>
                </svg>
                العقود
              </div>
            </Link>
            <Link to="/archives/sessions">
              <div className="single_folder">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="143.397"
                  height="109.924"
                  viewBox="0 0 143.397 109.924"
                >
                  <g id="folder">
                    <g id="Group_120" data-name="Group 120" transform="translate(0 0)">
                      <path
                        id="Path_163"
                        data-name="Path 163"
                        d="M130.744,62.043H72.514l-11.6-13.791a1.758,1.758,0,0,0-1.406-.633H12.653A12.779,12.779,0,0,0,0,60.413v84.351a12.779,12.779,0,0,0,12.653,12.779H130.744A12.779,12.779,0,0,0,143.4,144.764V74.823A12.779,12.779,0,0,0,130.744,62.043Z"
                        transform="translate(0 -47.619)"
                        fill="#ca2946"
                      />
                    </g>
                  </g>
                  <g id="gavel" transform="translate(45.281 32.918)">
                    <path
                      id="Path_41"
                      data-name="Path 41"
                      d="M240.99,423.206v5.848a1.456,1.456,0,0,0,1.456,1.456h23.392a1.456,1.456,0,0,0,1.456-1.456v-5.848a1.456,1.456,0,0,0-1.456-1.456H242.446A1.456,1.456,0,0,0,240.99,423.206Zm2.912,1.456h20.48V427.6H243.9Z"
                      transform="translate(-217.6 -380.814)"
                      fill="#fff"
                    />
                    <path
                      id="Path_42"
                      data-name="Path 42"
                      d="M1.291,43.837a4.385,4.385,0,0,0,6.194,0L25.064,26.258l2.076,2.076L26.1,29.373a1.456,1.456,0,0,0,0,2.059l4.135,4.135a1.456,1.456,0,0,0,2.059,0L44.7,23.161a1.456,1.456,0,0,0,0-2.059l-4.135-4.135a1.456,1.456,0,0,0-2.059,0L37.47,18.005,27.123,7.659,28.161,6.62a1.456,1.456,0,0,0,0-2.059L24.026.426a1.456,1.456,0,0,0-2.059,0L9.562,12.832a1.456,1.456,0,0,0,0,2.059L13.7,19.026a1.456,1.456,0,0,0,2.059,0l1.038-1.038,2.076,2.076L1.291,37.643a4.385,4.385,0,0,0,0,6.194ZM25.073,5.591,14.726,15.937,12.65,13.861,23,3.515ZM29.191,30.4,39.537,20.056l2.076,2.076L31.267,32.478Zm.009-4.127L18.853,15.929l6.211-6.211L35.411,20.064ZM3.35,39.7,20.929,22.123,23.005,24.2,5.427,41.778A1.468,1.468,0,0,1,3.35,39.7Z"
                      transform="translate(-0.011 0)"
                      fill="#fff"
                    />
                  </g>
                </svg>
                الجلسات
              </div>
            </Link>
            {/*<div className="single_folder">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="143.397"
                height="109.924"
                viewBox="0 0 143.397 109.924"
              >
                <g id="folder" transform="translate(0 0)">
                  <g id="Group_120" data-name="Group 120" transform="translate(0 0)">
                    <path
                      id="Path_163"
                      data-name="Path 163"
                      d="M130.744,62.043H72.514l-11.6-13.791a1.758,1.758,0,0,0-1.406-.633H12.653A12.779,12.779,0,0,0,0,60.413v84.351a12.779,12.779,0,0,0,12.653,12.779H130.744A12.779,12.779,0,0,0,143.4,144.764V74.823A12.779,12.779,0,0,0,130.744,62.043Z"
                      transform="translate(0 -47.619)"
                      fill="#ca2946"
                    />
                  </g>
                </g>
                <g id="calendar" transform="translate(45.747 33.366)">
                  <g id="Group_11" data-name="Group 11" transform="translate(0)">
                    <g id="Group_10" data-name="Group 10">
                      <ellipse
                        id="Ellipse_2"
                        data-name="Ellipse 2"
                        cx="2.5"
                        cy="1"
                        rx="2.5"
                        ry="1"
                        transform="translate(36.506 20.397)"
                        fill="#fff"
                      />
                      <path
                        id="Path_43"
                        data-name="Path 43"
                        d="M43.162,4h-2.6V2a2,2,0,0,0-4,0V4H27.475V2a2,2,0,0,0-4,0V4H14.487V2a2,2,0,0,0-4,0V4h-2.5A8,8,0,0,0,0,11.989V43.161a8,8,0,0,0,7.993,7.993H23.279a2,2,0,0,0,0-4H7.993a4,4,0,0,1-4-4V11.989a4,4,0,0,1,4-4h2.5v2a2,2,0,0,0,4,0v-2h8.992v2a2,2,0,0,0,4,0v-2h9.092v2a2,2,0,0,0,4,0v-2h2.6a4,4,0,0,1,4,4v11.39a2,2,0,0,0,4,0V11.989A8,8,0,0,0,43.162,4Z"
                        fill="#fff"
                      />
                      <path
                        id="Path_44"
                        data-name="Path 44"
                        d="M282.089,270a12.089,12.089,0,1,0,12.089,12.089A12.1,12.1,0,0,0,282.089,270Zm0,20.182a8.093,8.093,0,1,1,8.093-8.093A8.1,8.1,0,0,1,282.089,290.182Z"
                        transform="translate(-243.024 -243.024)"
                        fill="#fff"
                      />
                      <path
                        id="Path_45"
                        data-name="Path 45"
                        d="M375.9,334.1H375V332a2,2,0,1,0-4,0v4.1a2,2,0,0,0,2,2h2.9a2,2,0,1,0,0-4Z"
                        transform="translate(-333.933 -297.029)"
                        fill="#fff"
                      />
                      <ellipse
                        id="Ellipse_3"
                        data-name="Ellipse 3"
                        cx="2.5"
                        cy="1"
                        rx="2.5"
                        ry="1"
                        transform="translate(27.506 20.397)"
                        fill="#fff"
                      />
                      <ellipse
                        id="Ellipse_4"
                        data-name="Ellipse 4"
                        cx="2"
                        cy="2.5"
                        rx="2"
                        ry="2.5"
                        transform="translate(20.506 26.397)"
                        fill="#fff"
                      />
                      <ellipse
                        id="Ellipse_5"
                        data-name="Ellipse 5"
                        cx="2.5"
                        cy="1"
                        rx="2.5"
                        ry="1"
                        transform="translate(10.506 20.397)"
                        fill="#fff"
                      />
                      <circle
                        id="Ellipse_6"
                        data-name="Ellipse 6"
                        cx="2.5"
                        cy="2.5"
                        r="2.5"
                        transform="translate(10.506 26.397)"
                        fill="#fff"
                      />
                      <ellipse
                        id="Ellipse_7"
                        data-name="Ellipse 7"
                        cx="2.5"
                        cy="2"
                        rx="2.5"
                        ry="2"
                        transform="translate(10.506 35.397)"
                        fill="#fff"
                      />
                      <circle
                        id="Ellipse_8"
                        data-name="Ellipse 8"
                        cx="2"
                        cy="2"
                        r="2"
                        transform="translate(20.506 35.397)"
                        fill="#fff"
                      />
                      <ellipse
                        id="Ellipse_9"
                        data-name="Ellipse 9"
                        cx="2"
                        cy="1"
                        rx="2"
                        ry="1"
                        transform="translate(20.506 20.397)"
                        fill="#fff"
                      />
                    </g>
                  </g>
                </g>
              </svg>
              المواعيد
            </div>*/}
          </div>
        </div>
      </div>
    );
  }
}

export default Notification;
